import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../layout'

import BackgroundContainer from '../../components/image/background-container'
import FooterTitle from '../../components/common/footer-title'
import TitleContainer from '../../components/common/title-container'
import Title from '../../components/common/title'
import PageContainer from '../../components/common/page-container'
import Link from '../../components/common/link'

export default (props) => (
  <Layout title="Technical Consultancy – North East Technical Consultants">
    <BackgroundContainer header={props.data.header}>
      <TitleContainer>
        <Title>Technical Consultancy</Title>
      </TitleContainer>
    </BackgroundContainer>
    <PageContainer>
        <h2>Your technology partners</h2>
        <p>As your technology partners, we can advise, lead or support your technical operations. If you are just starting out building a SaaS business and need a team, processes and systems, or if you are looking to move to the next stage, our team can help.</p>
        <p>We have worked with businesses of all sizes. For some start-up businesses our team have served as their entire technology team: from CTO to web developer. In other cases, we have laid the technical foundation for the business, helped our clients hire their in-house team, and provided on-going support to help that team deliver using the right technical approaches. For larger businesses, we have lent our expertise to help tackle specific technical problems, review technical debt, audit processes and provide a second pair of eyes.</p>
        <ul>
            <li>Technical best practices</li>
            <li>Bridging the gap between management and technical teams, ideal for businesses that lack a technology executive or CTO.</li>
            <li>Hiring and growing a technical team</li>
            <li><strong><Link href="/services/technical-consultancy/technical-leadership" title="Technical leadership for your business and team">Technical leadership</Link></strong> for your business, board, in-house team or outsourced partners</li>
            <li>Leveraging cloud services</li>
            <li>Guiding your technical operations</li>
            <li>Training</li>
            <li><strong><Link href="/services/technical-consultancy/due-diligence" title="Due Diligence review for your project, team, technical partner or potential investment">Due-diligence</Link></strong> to review a <em>third-parties work</em>, provide an <em>outside look</em> at your product or team, to help get <strong><em>investment ready</em></strong>, or to <strong><em>review a potential investment</em></strong> you are looking to make.</li>
        </ul>
    </PageContainer>
    <BackgroundContainer header={props.data.footer}>
      <TitleContainer>
          <FooterTitle>Ready to start your technical consultancy project?</FooterTitle>

          <Link to="/contact" className="btn btn-lg mt-3 btn-primary">
              Get in touch
          </Link>
      </TitleContainer>
    </BackgroundContainer>
  </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "technical-consultancy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    footer: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
